.box {
  position: fixed;
  background: linear-gradient(
    325.17deg,
    #78ea83 11.28%,
    #51bf59 34.25%,
    #259528 79.61%
  );
}

.box-1 {
  position: fixed;
  background: linear-gradient(
    11.02deg,
    rgba(120, 234, 131, 0.1) -19.74%,
    rgba(37, 149, 40, 0.1) 64.4%
  );
}

.box-2 {
  position: fixed;
  background: linear-gradient(
    11.03deg,
    rgba(120, 234, 131, 0.1) -19.86%,
    rgba(37, 149, 40, 0.1) 64.37%
  );
}

.header {
  text-align: center;
  font-size: 48px;
  margin-bottom: 0px;
  color: #ffffff;
  margin: 20px;
}

.std-text {
  font-size: 20px;
  text-align: center;
  color: #ffffff;
}

.center-horizontal {
  justify-content: center;
}

.center {
  justify-content: center;
  align-items: center;
}

.version {
  justify-content: center;
  background-color: #292929;
  z-index: 1;
}

.version > h6 {
  text-align: center;
  color: #ffffff;
}

.space-between-content {
  justify-content: space-between;
}

.login-button-container {
  margin-top: 30px;
  display: flex;
  justify-content: center;
}

.no-text-select {
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

.no-pointer-events {
  pointer-events: none;
}

.z-1{
  z-index: 1 !important;
}

.vtp-spinner-dark {
  color: rgb(12, 97, 90);
}

.vtp-secondary-text {
  font-family: "Mulish";
  color: #808080;
}

.tooltip > .tooltip-inner {
  background-color: rgba(0, 0, 0, 0.4);
  /* Body/Small Body */

  font-family: 'Mulish';
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  display: flex;
  align-items: center;
  color: #FFFFFF;
}

.tooltip-arrow::before {
  border-top-color: rgba(0, 0, 0, 0.4) !important;
  transform: translateY(1px);
}