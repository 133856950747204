@value common: "./ui.module.css";
@value text: "./text.module.css";
@value defaultFont, defaultStyle from common;

.mega {
    font-family: defaultFont;
    font-style: defaultStyle;
    font-weight: 700;
    font-size: 60px;
    line-height: 87px;
}

.h1TitleLarge {
    font-family: defaultFont;
    font-style: defaultStyle;
    font-weight: 700;
    font-size: 44px;
    line-height: 130%;
}

.h1TitleMedium {
    font-family: defaultFont;
    font-style: defaultStyle;
    font-weight: 700;
    font-size: 40px;
    line-height: 130%;
}

.h1TitleSmall {
    font-family: defaultFont;
    font-style: defaultStyle;
    font-weight: 700;
    font-size: 36px;
    line-height: 130%;
}

.h2SubheaderLarge {
    font-family: defaultFont;
    font-style: defaultStyle;
    font-weight: 700;
    font-size: 28px;
    line-height: 130%;
}

.h2SubheaderMedium {
    font-family: defaultFont;
    font-style: defaultStyle;
    font-weight: 700;
    font-size: 24px;
    line-height: 35px;
}

.h2SubheaderSmall {
    font-family: defaultFont;
    font-style: defaultStyle;
    font-weight: 700;
    font-size: 20px;
    line-height: 130%;
}

.h3EyebrowLarge {
    font-family: defaultFont;
    font-style: defaultStyle;
    font-weight: 600;
    font-size: 22px;
    line-height: 130%;
    text-transform: uppercase;
}

.h3EyebrowMedium {
    font-family: defaultFont;
    font-style: defaultStyle;
    font-weight: 600;
    font-size: 18px;
    line-height: 130%;
    text-transform: uppercase;
}

.h3EyebrowSmall {
    font-family: defaultFont;
    font-style: defaultStyle;
    font-weight: 600;
    font-size: 14px;
    line-height: 130%;
    text-transform: uppercase;
}

.h4Caption {
    font-family: defaultFont;
    font-style: defaultStyle;
    font-weight: 500;
    font-size: 12px;
    line-height: 17px;
}