.container {
    
}

.header {
    display: flex;
    align-items: center;
    justify-content: left;
    height: 87px;
    padding-left: 24px;
}

.header svg{
    width: auto;
}

.header svg path {
    fill: #1d2132 !important;
}

.header svg:last-child{
    height: 35px;
    margin-left: -8px;
}

.content {
    background-image: url("../../assets/gradient-shape.svg");
    background-size: 100% 100%;
    width: 100%;
    padding: 20px 30px 95px 30px;
    display: flex;
    justify-content: center;
}