.vtpNumericInputBox {
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 0px 2px;
    gap: 10px;
    width: 135px;
    height: 55px;
    border: 2px solid #0C615A;
    border-radius: 11.8462px;
}

.vtpNumericInputBox.small {
    width: 90px;
    height: 38px;
    border-radius: 9px;
    gap: 6.5px;
}

.vtpNumericInputBox.error {
    border: 2px solid #BC4D4D;
}

.vtpNumericInputBox.disabled {
    border: 2px solid #C4C4C4;
}

.vtpNumericInputSpinner {
    font-family: 'Noto Sans';
    font-style: normal;
    font-weight: 600;
    font-size: 25.3846px;
    line-height: 35px;
    display: flex;
    align-items: center;
    text-align: center;
    color: #0C615A;
}

.vtpNumericInputSpinner.small {
    font-size: 17.5px;
}

.vtpNumericInputSpinnerSvg.small {
    width: 23px;
}

.vtpNumericInputSpinnerSvg.error {
    filter: hue-rotate(180deg) saturate(200%);
}

.vtpNumericInputSpinnerSvg.disabled {
    filter: saturate(0%) contrast(0%) brightness(130%);
}

.vtpNumericInputSpinner-svg:hover {
    fill: #e3ebe9;
}

.vtpNumericInputSpinner-svg:active:hover {
    fill: #D0E2E2;
}

.vtpNumericInputSpinnerMinus {
    cursor: pointer;
    margin-left: -2px;
}

.vtpNumericInputSpinnerPlus {
    margin-left:0px;
    width:100%;
    cursor: pointer;
}

.vtpNumericInputCounter {
    width:100%;
}

.vtpNumericInputInput {
    max-width: 40px;
    font-family: 'Mulish';
    font-style: normal;
    font-weight: 500;
    font-size: 28px;
    line-height: 130%;
    border:none;
    display: flex;
    align-items: center;
    text-align: center;
    color: #0C615A;
}

.vtpNumericInputInput.small {
    max-width: 25px;
    font-weight: 400;
    font-size: 18px;
}

.vtpNumericInputInput.error {
    color: #BC4D4D;
}

.vtpNumericInputInput.disabled {
    color: #C4C4C4;
}

.vtpNumericInputInput.textarea:focus, input:focus{
    outline: none;
}

.vtpNumericInputCounter input::-webkit-outer-spin-button,
.vtpNumericInputCounter input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
}

.vtpNumericInputValue
{}

/* Firefox */
.vtpNumericInputCounter input[type=number] {
    -moz-appearance: textfield;
}