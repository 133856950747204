.container {
    position: fixed;
    bottom: 0;
    max-width: 1329px;
    width: 100%;
    z-index: 1000;
}

.uploadManager {
    margin-left: auto;
    background-color: #D0E2E2;
    width: 270px;
    margin-right: 50px;
    border-radius: 5px 5px 0 0;
    transition: height;
}

.header {
    display: flex;
    align-items: center;
    padding-left: 24px;
    padding-right: 31px;
    justify-content: space-between;
    cursor: pointer;
}

.headerOpen {
    height: 39px;
    align-items: center;
    display: flex;
}

.headerClosed {
    display: flex;
    flex-direction: column;
    justify-content: end;
    padding-bottom: 8px;
    padding-top: 11px;
}

.headerClosed > div {
   margin-bottom: 4px; 
}

.header:hover {
    background-color: rgb(166, 181, 181);
    border-radius: 5px 5px 0 0;
}

.header:hover * {
    color: #FFFFFF;
    fill: #FFFFFF;
}

.arrow {
    display: flex;
    transition-duration: 0.15s;
    transition-property: transform;
}

.arrow svg path{
    fill: #0C615A;
}

.arrowOpen {
    transform: rotate(180deg);
}

.arrowClosed {
    transform: rotate(0deg);
}

.fileUploadRows {
    max-height: 200px;
    overflow-y: auto;
    overflow-x: hidden;
    margin-right: 8px;
    padding-top: 2px;
    padding-bottom: 12px;
}

.fileUploadRows::-webkit-scrollbar {
    width: 7px;
}

.fileUploadRows::-webkit-scrollbar-track {
    background: #d0e2e2;
    border-radius: 5px;
}

.fileUploadRows::-webkit-scrollbar-thumb {
    background: #0c615a;
    border-radius: 9px;
}

.fileUploadRow {
    display: flex;
    align-items: center;
    width: 253px;
    height: 33px;
    padding-left: 24px;
    padding-right: 2px;
}

.fileUploadRow + .fileUploadRow {
    margin-top: 16px;
}

.fileNameGroup {
    display: flex;
    justify-content: center;
    flex-direction: column;
    height: 100%;
    max-width: 122px;
}

.error.fileNameGroup * {
    color: #BC4D4D;
}

.errorMessage {
    width: 122px;
    max-height: 17px;
    margin: 0;
}

.fileName {
    min-height: 20px;
    height: 20px;
    overflow: hidden;
    text-overflow: ellipsis;
}

.fileName + .progress {
    margin-top: 5px;
}

.fileIcon {
    height: 30px;
    width: 20px;
    display: flex;
    align-items: center;
    margin-right: 12px;
}

.progress {
    width: 122px;
    background-color: #E3EBE9;
    border-radius: 4px;
    height: 8px;
}

.progress .progressBar {
    height: 100%;
    width: 0;
    border-radius: 4px;
    background-color: #0C615A;
    transition: width 0.7s;
}

.buttonGroup {
    display: inline-flex;
    width: 100%;
    height: 100%;
    justify-content: end;
    gap: 2px;
}

.removeButton {
    height: 30px;
    width: 30px;
}

.removeButton svg {
    transform: translateX(-10px);
}