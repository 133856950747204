.tableContainer tbody td:nth-child(3) > * {
    margin-left: -15px;
    user-select: text;
}

.paging {
    margin-top: 28px;
}

.userSessionDetails {
    display: flex;
    justify-content: end;
    gap: 40px;
    width: 100%;
}

.userSessionDetails > div {
    display: flex;
    gap: 10px;
    align-items: center;
}

.boldHeader {
    font-family: Mulish;
    font-size: 18px;
    font-weight: 700;
}

.userName {
    height: 26px;
    top: 114px;
    left: 370px;
    margin-left: 47px;
}