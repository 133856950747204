.banner {
    position: fixed;
    width: 100%;
    z-index: 3;
    top: 0;
}

.notification {
    display: flex;
    align-items: center;
    width: 100%;
    padding: 20px 30px;
    background-color: #FDF4D5;

    border-bottom: 1px solid rgba(217, 172, 47, 0.3);
}

.notificationMessage {
    color: #997721;
    text-align: center;
    width: 100%;
    padding-right: 10px;
    font-weight: 500;
}

.closeIcon {
    height: 25px;
    cursor: pointer;
    margin-left: auto;
}

.notificationMessage a {
    color: #D9AC2F;
}

.notificationMessage a:hover {
    filter: brightness(0.85);
}

.closeIcon:hover * {
    filter: brightness(0.9);
}

.closeIcon * {
    fill: #D9AC2F;
}
