@value vtpTextInputContainer from "../base/form/vtpTextInput.module.css";

.vtpModal {
  display: flex !important;
  align-items: center;
}

.vtpModal > :global(.modal-dialog) {
  max-width: 100%;
  width: auto !important;
  display: flex !important;
  justify-content: center;
  min-width: 608px;
}

.vtpModal .vtpTextInputContainer {
  width: 100%;
}

.vtpModalButtons {
  display: flex;
  justify-content: end;
  padding: 0;
}

.vtpModal > :global(.modal-dialog) > :global(.modal-content) {
  padding: 64px 64px 80px 64px;
}

@media (max-width: 600px) {
  .vtpModal > :global(.modal-dialog) {
    min-width: unset;
  }

  .vtpModalButtons {
    flex-direction: column-reverse;
    width: 300px;
    margin-left: auto;
    margin-right: auto;
  }

  .vtpModalButtons > :global(.btn) + :global(.btn) {
    margin-bottom: 15px;
  }
}

@media (max-width: 520px) {
  .vtpModal > :global(.modal-dialog) {
    margin-left: auto;
    margin-right: auto;
  }
  .vtpModal > :global(.modal-dialog) > :global(.modal-content) {
    padding: 30px 10px 30px 15px;
  }
}

.vtpModalTitle {
  margin-bottom: 32px;
}

.vtpModalTitle > h1 {
  padding: 0;
  font-family: "Mulish";
  font-style: normal;
  line-height: 130%;
  font-weight: 600;
  font-size: 40px;
  color: #414141;
  /*padding: 0;*/
  /*margin: 0;*/
}

.vtpModalTitleItems {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.vtpModalTitleIcon {
  margin-right: 8px;
  display: flex;
}