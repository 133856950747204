.container {
  position: relative;
}

.moreButton {
  height: 24px;
  width: 24px;
  margin: 14px 0;
  cursor: pointer;
  
  outline: 7px solid transparent;
  border-radius: 50%;
}

.moreButton:hover {
  background: #00000033;
  outline-color: #00000033;
}

.moreButton:active {
  background: #D0E2E2;
  outline-color: #D0E2E2;
}

.contextMenu {
  position: absolute;
  width: auto;
  padding: 18px 0;
  height: auto;
  white-space: nowrap;

  z-index: 2;
  background: #ffffff;
  border-radius: 15px;
  box-shadow: 0 0 15px rgba(0, 0, 0, 0.2);

  gap: 12px;
}

.contextMenu .contextMenuRow {
  text-decoration: none;
  min-height: 36px;
  cursor: pointer;
  display: inline-flex;
  flex: 1 0 auto;
  background-color: white;
  padding: 0 32px;
  width: 100%;
  gap: 7px
}


.contextMenuRow:hover {
  background: rgba(0, 0, 0, 0.2);
}

.contextMenuRow > span {
  width: auto;
  text-transform: none;
}

.contextMenuIcon {
  display: inherit;
  width: 24px;
}

.contextMenuIcon > svg {
  width: 24px;
  height: 24px;
}

.disabled {
  cursor: unset !important;
}

.disabled span{
  color: #C4C4C4;
}
.disabled path {
  fill: #C4C4C4;
}

.disabled:hover{
  background: white;
}

.buttonOverlay {
  position: absolute;
  top: 0;
}
