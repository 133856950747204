/* CSS Module Imports */
@value vtpTextPlaceholderInput from "./form/vtpTextInput.module.css";
@value vtpTextInputError from "./form/vtpTextInput.module.css";

.vtpFormCardContainer {
  max-width: 913px;
  width: 100%;
  position: relative;
}

.vtpCardContainer {
  padding-bottom: 7px;
}

.vtpFormCardHeader {
  padding-top: 8px;
  padding-bottom: 16px;
}

.vtpFormCardRowContainer {
  display: flex;
  align-items: flex-end;
  min-height: 66px;
  padding: 0 32px 8px 0;
}

.vtpFormCardRow {
  display: flex;
  justify-content: space-between;
  height: auto;
  align-items: center;
  margin-left: 32px;
  min-height: 32px;
  width: 100%;
}

.vtpFormCardRows {
  padding-bottom: 19px;
  padding-top: 8px;
}

.vtpFormCardRowLeft {
}

.vtpFormCardRowRight {
  width: 50%;
  display: flex;
  justify-content: end;
}

.vtpFormCardRowContainer + .vtpFormCardRowContainer {
  border-top: 1px solid #c4c4c4;
}

.vtpFormCardButtons {
  padding-top: 9px;
  padding-bottom: 15px;
  padding-right: 32px;
  display: flex;
  justify-content: end;
}

.vtpFormCardButtons > button + button {
  margin-left: 16px;
}

.vtpFormCardTextInput .vtpTextInputError {
  /*right: 0;*/
  transform: translate(0px, -25px);

  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
}

.vtpFormCardTextInput input:read-only {
  padding-right: 0;
}

.vtpFormCardCollapsableSection {
  display: flex;
  justify-content: center;
  cursor: pointer;
}

.titleSection {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-left: 32px;
  padding-top: 18px;
  padding-right: 24px;
  margin-bottom: -5px;
}
