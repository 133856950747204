.container {
    width: 498px;
    height: 670px;
    padding: 32px;
    display: flex;
    flex-direction: column;
}

.header {
    color: #414141;
}

.subheader {
    color: #414141;
    margin: 24px 0 16px 0;
}

.email {
    max-width: 190px;
    white-space: nowrap; 
    overflow: hidden; 
    text-overflow: ellipsis;
}

.userName {
    max-width: 210px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

.btnGroup {
    border-top: #D0E2E2 1.5px solid;
    padding-top: 24px;
    margin-top: auto;
    display: flex;
    justify-content: end;
    gap: 32px;
}

.userList {
    overflow: hidden;
    overflow-y: auto;
    margin-top: 6px;
    margin-bottom: 12px;
}

.userList thead {
    display: none;
}

.userList tbody tr:not(:first-child) {
    border-top: none;
}

.userList tbody > tr:last-child {
    border-bottom: none;
}

.userList td {
    height: 33px;
}

.container th:nth-child(1),
.container td:nth-child(1){
    width: 210px;
}

.userList::-webkit-scrollbar {
    width: 13px;
}

.userList::-webkit-scrollbar-track {
    background: #d0e2e2;
    border-radius: 5px;
}

.userList::-webkit-scrollbar-thumb {
    background: #0c615a;
    border-radius: 9px;
}