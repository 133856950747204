.supportPageButton {
    margin-left: auto;
    transform: translateY(4px);
}

.supportPageButton path{
    stroke: #0c605a;
}

.organizationDropdownSection {
    display: flex;
    align-items: center;
    gap: 10px;
    margin-top: 20px;
}

.deleteOrganizationButton {
    background-color: #853232;
    border-color: #853232;
}

.deleteOrganizationButton:hover{
    background-color: #632525;
    border-color: #632525;
}

.organizationDropdown {
    width: 713px;
    position: relative;
    background-color: transparent;
}

.organizationDropdown > div:nth-child(2){
    transform: translateY(-16px);
}

.search span {
    color: #808080;
    font-family: 'Mulish';
    font-style: normal;
    font-weight: 500;
    font-size: 20px;
    line-height: 30px;
}

.search {
    max-width: 169px;
    height: 48px;
}

.search:focus-within {
    border: solid 2px #0C615A;
}

.search svg {
    min-height: 28px;
    min-width: 28px;
    margin-left: 18px;
    margin-right: -5px;
}

.licenseList {
    margin-top: 32px;
    width: 913px;
}

.header {
    height: 88px;
    display: flex;
    align-items: center;
    padding: 0 32px;
    justify-content: space-between;
    cursor: pointer;
}

.licenseName {
    width: 200px;
}

.licenseCard {
    margin-top: 25px;
}

.licenseCard + .licenseCard{
    margin-top: 18px;
}

.licenseTokenList {
    padding-bottom: 32px;
}

.licenseTokenList th:nth-child(2) {
    width: 120px;
}

.readOnlyCheckbox {
    pointer-events: none;
}

.licenseReference {
    position: absolute;
    color: #787878;
    font-size: 12px;
    font-family: Mulish;
    transform: translateY(-10px);
}

.licenseAssignmentContextMenu > svg {
    margin: 0 0 3px 0;
}