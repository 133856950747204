.statusPill {
    padding: 5px 20px 5px 20px;
    background-color: #E8E8E8;
    width: fit-content;
    border-radius: 30px;

    font-size: 12px;
    font-weight: 500;
    line-height: 17px;
    color: #808080;
    margin-right: 10px;
}

.statusPill.active {
    background-color: #E1F9E8;
    color: #5AAA5F;
}

.statusPill.licensed {
    background-color: #E1F0F9;
    color: #5A71AA;
}