.assignUsersContainer {
    width: 826px;
    height: 706px;
    display: flex;
    flex-direction: column;
    padding: 0 32px;
}

.header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 102px;
}

.subHeader{
    width: 700px;
    margin-top: -25px;
    margin-bottom: 15px;
}

.controls {
    display: flex;
    align-items: center;
    justify-content: end;
    gap: 16px;
    margin-bottom: 30px;
    padding-left: 10px;
}

.controls div:first-child {
    margin-right: auto;
}

.alreadyAssignedLabel {
    margin-top: 6px;
    margin-left: 6px;
    margin-bottom: 6px;
}


/* ===== Styling tables ====== */
.userList {
    height: 386px;
    overflow: hidden;
    overflow-y: auto;
    margin-top: 7px;
}

.userList table{
    width: 762px;
}

.userList::-webkit-scrollbar {
    width: 13px;
}

.userList::-webkit-scrollbar-track {
    background: #d0e2e2;
    border-radius: 5px;
}

.userList::-webkit-scrollbar-thumb {
    background: #0c615a;
    border-radius: 9px;
}

.userList thead {
    display: none;
}

.userList tbody tr:not(:first-child) {
    border-top: none;
}

.userList td {
    height: 41px;
}

.assignUsersContainer tbody > tr:last-child {
    border-bottom: none;
}

.assignUsersContainer th:nth-child(1),
.assignUsersContainer td:nth-child(1){
    width: 40px;
}

.assignUsersContainer th:nth-child(2),
.assignUsersContainer td:nth-child(2){
    width: 170px;
}

.assignUsersContainer th:nth-child(3),
.assignUsersContainer td:nth-child(3){
    width: 150px;
}

.assignUsersContainer th:nth-child(4),
.assignUsersContainer td:nth-child(4){
    width: 100px;
}

.assignUsersContainer th:nth-child(5),
.assignUsersContainer td:nth-child(5){
    width: 110px;
}

/* ==== bottom button group ====*/
.buttonGroup {
    border-top: #D0E2E2 1.5px solid;
    margin-top: auto;
    height: 99px;
    display: flex;
    align-items: center;
    justify-content: end;
}
