.container {
    max-width: 760px;
    min-width: 480px;
    width: 100%;
    display: flex;
    flex-direction: column;
    flex: 0 0 auto;
    justify-content: center;
    overflow: hidden;
}

.noItems {
    display: flex;
    flex-direction: column;
    align-items: center;
    min-width: 480px;
    margin-top: 20px;
}

.noItems svg {
    opacity: 0.1;
    width: 325px;
}

.noItems svg path {
    fill: #1d2132 !important;
}

.message {
    transform: translateY(-20px);
}