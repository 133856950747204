@value ui: "./ui.module.css";
@value defaultFont, defaultStyle from ui;

.buttonsLarge,
.buttonsMedium,
.buttonsSmall,
.link
{
    font-family: defaultFont;
    font-style: defaultStyle;
}

.link {
    font-weight: 400;
    font-size: 18px;
    line-height: 26px;
}

.buttonsLarge {
    font-weight: 500;
    font-size: 24px;
    line-height: 36px;
    font-feature-settings: 'tnum' on, 'lnum' on, 'ss01' on;
}	

.buttonsMedium {
    font-weight: 500;
    font-size: 20px;
    line-height: 30px;
}

.buttonsSmall {
    font-weight: 500;
    font-size: 16px;
    line-height: 24px;
}