.sortingLabel {
    display: flex;
    align-items: center;
    gap: 1px;
    cursor: pointer;
}

.sortingLabel svg {
    margin-bottom: 1px;
}

.sortingLabel.asc path:nth-child(1) {
    transform: translateY(4px);
}

.sortingLabel.asc path:nth-child(2) {
    fill: transparent;
}

.sortingLabel.desc path:nth-child(1) {
    fill: transparent;
}

.sortingLabel.desc path:nth-child(2) {
    transform: translateY(-4px);
}