.loginHeaderContainer {
  display: flex;
  justify-content: center;
}

.loginHeader {
  font-family: "Mulish";
  font-style: normal;
  font-weight: 600;
  font-size: 36px;
  line-height: 130%;
  color: #414141;
}

.kandaLogo {
  max-height: 100%;
  max-width: 100%;

  width: 40%;
  height: 40%;
  min-width: 200px;
  min-height: 262px;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  margin: auto;
}

.loginPaneContainer {
  width: 58%;
  margin: auto;
}

.loginPaneSpacing {
  padding-top: 280px;
}

.loginPaneSpacingMobile {
  padding-top: 60%;
}
