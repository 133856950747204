.container {
    position: relative;
    /*min-height: 1000px;*/
    min-height: 75%;
}

.courseFileList {
    padding: 24px 64px;
    background-color: #E3EBE9;
    height: 100%;
    display: flex;
    flex-direction: column;
    gap: 24px;
}

.filler {
    /*height: 100px;*/
    background-color: #E3EBE9;
}

.searchInput {
    position: absolute;
    padding: 0 64px;
    right: 0;
    top: -61px;
}

.collapsibleCourseSection {
    /*display: flex;*/
}

.collapsibleCourseSection h2 {
    margin: 0;
}

.header {
    display: flex;
    align-items: center;
    gap: 12px;
    cursor: pointer;
}

.arrowIcon {
    scale: 2;
    transition-duration: 0.15s;
    transition-property: transform;
}

.arrowIcon > path {
    fill: #808080;
}

.open {
    transform: rotate(180deg);
}

.closed {
    transform: rotate(90deg);
}

.folderGroup {
    margin-top: 15px;
    display: flex;
    flex-direction: column;
    gap: 16px;
}

.folder {
}

.folderHeader {
    height: 66px;
    display: flex;
    justify-content: end;
    align-items: center;
    padding: 0 32px;
    cursor: pointer;
}

.folderHeader > div:first-child {
    margin-right: auto;
}

.folderNameUnderline {
    margin-top: 8px;
    border-top: 1.5px solid #0C615A;
    width: 50px;
}

.folderSizeGroup {
    width: 80px;
}

.fileNameGroup {
    display: inline-flex;
    align-items: center;
    gap: 9px;

    border-radius: 33px;
    padding: 6px 10px 6px 10px;
    margin-right: 15px;
    cursor: pointer;
}

.fileNameGroup svg {
    transform: translateY(-2px);
}

.fileNameGroup:hover {
    background: #00000033;
    outline-color: #00000033;
}

.fileNameGroup:hover path {
    fill: #0c615a;
}

.fileNameGroup:active {
    background: #D0E2E2;
    outline-color: #D0E2E2;
}

.fileName {
    width: auto;
    max-width: 320px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

.courseFilesTable {
    padding: 0 32px;
    margin-bottom: 12px;
}

.courseFilesTable thead h3 {
    margin-bottom: 2px;
}

.courseFilesTable thead th:nth-child(1) {
    padding-left: 8px !important;
}

.courseFilesTable thead th:nth-child(1) {
    width: 490px;
}

.courseFilesTable thead th:nth-child(2) {
    width: 200px;
}

.courseFilesTable thead th:nth-child(3) {
    width: 200px;
}

.courseFilesTable tbody td:first-child {
    padding-bottom: 5px;
    padding-left: 0px;
}

.courseFilesTable tbody td:last-child {
    padding-right: 5px;
    padding-bottom: 5px;
}

.optionsButton > div {
    margin-left: auto;
    width: fit-content;
    margin-right: 8px;
}

.downloadButton {
    margin-left: auto;
    width: 41px;
}

.downloadButton svg {
    scale: 0.9;
    transform: translateX(-4px);
}

.loadingSpinner {
    display: flex;
    justify-content: center;
    align-items: center;
    max-width: 913px;
}

.addFilesButton {
    display: flex;
    align-items: center;
    justify-content: center;
}

.addFilesButton svg{
    transform: scale(0.65);
}

.addFilesButton path{
    stroke: #0C615A;
}

.downloadIcon {
    width: 16px !important;
    transform: translateX(-1px);
}