.container {
    width: 608px;
    /*height: 296px;*/
    padding: 32px 32px;
    display: flex;
    flex-direction: column;
}

.header {
    margin: 0;
    white-space: pre-wrap;      /* CSS3 */
    white-space: -moz-pre-wrap; /* Firefox */
    white-space: -o-pre-wrap;   /* Opera 7 */
    word-wrap: break-word;      /* IE */
}

.nameInputGroup {
    display: flex;
    align-items: center;
    margin-top: 24px;
    gap: 16px
}

.nameInputGroup button {
    border-radius: 10px;
    width: 98px;
    padding: 0;
    display: flex;
    justify-content: center;
}

.nameInput {
    border-radius: 10px;
    width: 430px;
    height: 56px;
}

.nameInput input {
    font-size: 14px;
}

.radioInputLabel {
    margin-top: 24px;
}

.radioInputs {
    margin-top: 8px;
}