@value textStyles: "../../styles/text.module.css";
@value vtpPrimaryColor from textStyles;
@value uiStyles: "../../styles/ui.module.css";
@value vtpPrimaryPanelContrastColor from uiStyles;

.singleSectionPage {
    background-color: vtpPrimaryPanelContrastColor;
    height: 100%;
}

.container {
    padding: 58px 64px;
}

/*.container + .container {*/
/*    margin-top: 32px;*/
/*}*/

.header {
    display: inline-flex;
    align-items: center;
    gap: 16px;
    margin-bottom: 2px;
    width: 100%;
}

.header h1 {
    margin: 0;
}

.icon {
    display: flex;
    justify-content: center;
    width: 31px;
    height: 31px;
}

.icon svg {
    width: inherit;
    height: inherit;
}

.icon path{
    fill: vtpPrimaryColor;
}

.subheader {
    margin-bottom: 33px;
}

.content {
}

