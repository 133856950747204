.layoutContainer {
    display: flex;
    flex-direction: column;
    max-height: 100vh;
    height: 100vh;
    overflow: hidden;
    overflow-y: hidden;
}

.layoutGroup {
    height: 100%;
    display: flex;
    flex-direction: row;
}

.content {
    width: 100%;
    overflow: auto;
}