.container {
    padding: 32px;
    width: 498px;
}

.header {
}

.body {
    
}

.buttonSection {
    display: flex;
    justify-content: end;
    gap: 32px;
}

@media only screen and (max-width: 767px) {
    .buttonSection {
        display: flex;
        justify-content: end;
    }
}

@media only screen and (max-width: 400px) {
    .buttonSection {
        display: flex;
        justify-content: end;
        gap: 5px;
    }
}

.modalSection + .modalSection{
    margin-top: 24px;
}

.confirmationModalHeader {
    display: flex;
    gap: 20px;
    align-items: center;
    min-height: 40px;
}