.vtpLabelContainer{
    padding: 0;
}

.vtpLabelContainer > label {
    font-family: 'Mulish';
    font-style: normal;
    font-weight: 500;
    font-size: 20px;
    line-height: 130%;
    /* identical to box height, or 26px */

    display: flex;
    align-items: center;

    /* VTP Text/Secondary */

    color: #808080;
}