/* CSS Module Imports */
@value vtpLabelContainer from "../base/form/vtpLabel.module.css";
@value vtpTextInputContainer from "../base/form/vtpTextInput.module.css";

.setupFlowContainer {
  display: flex;
  flex-direction: column;
  min-height: 616px;
  justify-content: space-between;
  align-items: center;
  padding: 54px 20px;
}

.setupTopText {
  max-width: 750px;
  text-align: center;
  margin-top: 17px;
}

.setupBottomText {
  display: flex;
  flex-direction: column;
  max-width: 818px;
  text-align: center;
}

.setupTextInput {
  margin-top: 32px;
  margin-bottom: 32px;
}

.setupFlowContainer .vtpLabelContainer > label {
  font-size: 20px;
  font-weight: 500;
}

.guidedFlowWorkMode {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 68%;
  margin-top: 25px;
}

.guidedFlowWorkChoice {
  display: flex;
  flex-direction: column;
  width: 240px;
  height: 265px;
  border: 2px solid #e3ebe9;
  border-radius: 15px;
  justify-content: center;
}

.guidedFlowWorkChoice:hover {
  cursor: pointer;
}

.guidedFlowChoiceHighlight {
  border: 2px solid #0c615a;
}

.guidedFlowWorkChoiceLogoContainer {
  display: flex;
  justify-content: center;
  padding-top: 5px;
}

.guidedFlowWorkChoiceTextContainer {
  display: flex;
  justify-content: center;
  padding-top: 27px;
  font-family: "Mulish";
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
}

.guidedFlowRadioButton {
  width: 30px;
  height: 30px;
}

.setupFlowContainer .vtpTextInputContainer {
  width: 100%;
}

.guidedFlowCompanyDetails {
  margin: 64px 32px 32px 32px;
  min-width: 480px;
}