.title {
    max-width: 430px;
    width: 100%;
}

.inputContainer {
    transform: translate(-16px, -5px);
}

.inputContainer input {
    text-align: left;
}

.inputContainer > div {
    width: 350px;
}

.underline {
    max-width: 50px;
    border-top: 2px solid #0C615A;
}