/*  Large version  */
.vtpToggleSwitchLarge {
    position: relative;
    display: inline-block;
    width: 70px;
    height: 34px;
}

.vtpToggleSwitchLarge .slider {
    position: absolute;
    cursor: pointer;
    top: 7px;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: #EEEEEE;
    -webkit-transition: .4s;
    transition: .4s;
    height: 20px;
    width: 70px;
    border-radius: 20px;
}

.vtpToggleSwitchLarge .slider:before {
    position: absolute;
    content: "";
    height: 30px;
    width: 30px;
    left: 0;
    bottom: -5px;
    background-color: #C4C4C4;
    -webkit-transition: .4s;
    transition: .4s;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.25);
    border-radius: 24px;
}

.vtpToggleSwitchLarge .slider:hover::before {
    background-color: #9D9D9D;
}

.vtpToggleSwitchLarge input:checked + .slider {
    background-color: #D0E2E2;
}

.vtpToggleSwitchLarge input:checked + .slider:hover::before {
    background-color: #0A4E48;
}

.vtpToggleSwitchLarge input:checked + .slider:before {
    -webkit-transform: translateX(40px);
    -ms-transform: translateX(40px);
    transform: translateX(40px);
    background-color: #0C615A;
}

.vtpToggleSwitchLarge input.disabled:checked + .slider:before {
    background-color: #EEEEEE;
}


/*  Small version  */
.vtpToggleSwitchSmall {
    position: relative;
    display: inline-block;
    width: 13px;
    height: 25px;
}

.vtpToggleSwitchSmall .slider {
    position: absolute;
    cursor: pointer;
    top: 7px;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: #C4C4C4;
    -webkit-transition: .4s;
    transition: .4s;
    height: 13px;
    width: 25px;
    border-radius: 20px;
}

.vtpToggleSwitchSmall .slider:before {
    position: absolute;
    content: "";
    height: 11px;
    width: 11px;
    left: 1px;
    top: 1px;
    background-color: #808080;
    -webkit-transition: .4s;
    transition: .4s;
    box-shadow: 0 1px 2px 0 #00000040;
    border-radius: 24px;
}

.vtpToggleSwitchSmall input:checked + .slider {
    background-color: #0C615A;
}

.vtpToggleSwitchSmall input:checked + .slider:before {
    -webkit-transform: translateX(12px);
    -ms-transform: translateX(12px);
    transform: translateX(12px);
    background-color: #71E77D;
}

.vtpToggleSwitchSmall input.disabled:checked + .slider:before {
    background-color: #EEEEEE;
}

/*  Common for both versions  */
.vtpToggleSwitchLarge input,
.vtpToggleSwitchSmall input {
    opacity: 0;
    width: 0;
    height: 0;
}
