.paginationContainer {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0;
  gap: 70px;
  height: 76px;
}

.paginationTextEnabled:hover {
  cursor: pointer;
}

.paginationTextDisabled:hover {
  cursor: default;
}

.paginationButton {
  padding: 0;
}

.paginationStep {
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 10px 25px 10px 30px;
  gap: 10px;
  flex-grow: 0;
  border-radius: 33px;
}

.paginationTextDisabled {
  height: 35px;
  display: flex;
  align-items: center;
}

.paginationTextEnabled {
  height: 50px;
}

.paginationChevronEnabled {
  fill: #0c615a !important;
  height: 14px;
}

.paginationChevronDisabled {
  fill: #C4C4C4;
  height: 14px;
}

.paginationStep:has(.paginationChevronEnabled):hover {
  background: #00000033;
  outline-color: #00000033;
}

.paginationStep:has(.paginationChevronEnabled):active {
  background: #D0E2E2;
  outline-color: #D0E2E2;
}
