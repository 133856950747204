.container{
    display: flex !important;
    align-items: center;
}

.container :global(.modal-dialog){
    max-width: unset;
    width: fit-content;
}

.container :global(.modal-content){
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.25);
    border-radius: 10px;
}

.closeButton {
    position: absolute;
    top: 27px;
    right: 24px;
}