@value common: "./ui.module.css";
@value text: "./text.module.css";
@value defaultFont, defaultStyle from common;

.smallBody,
.mediumBody,
.largeBody {
    font-family: defaultFont;
    font-style: defaultStyle;
}

.smallBody {
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
}

.mediumBody {
    font-weight: 400;
    font-size: 18px;
    line-height: 26px;
}

.largeBody {
    font-weight: 400;
    font-size: 22px;
    line-height: 32px;
}