.tableContainer {

}

.tableContainer tbody td:first-child > * {
    margin-left: -13px;
    user-select: text;
}

.tableContainer thead th:last-child {
    width: 190px;
}