.container {
    background-color: #E1F9E8;
    height: 40px;
    display: flex;
    align-items: center;
    padding: 0 10px;
    border-radius: 10px;
    gap: 10px;
    color: #5AAA5F;
}

.addLicenseButton {
    cursor: pointer;
}

.addLicenseButton:hover circle{
    fill: #48884c;
}

.warning {
    background-color: #FDF4D5;
    color: #D9AC2F;
}

.warning circle {
    fill: #D9AC2F;
}

.warning svg:hover circle {
    fill: #ae8a26;
}

.danger {
    background-color: #F9E1E1;
    color: #AA5A5A;
}

.danger circle {
    fill: #AA5A5A;
}

.danger svg:hover circle {
    fill: #884748;
}