.container {
    position: relative;
}

.userTable tbody td:first-child button{
    transform: translateX(-15px);
}

.userTable thead th:nth-child(3) > h3 {
    transform: translateX(20px);
}

.userTable thead th:nth-child(4) > h3 {
    transform: translateX(15px);
}

.userTable thead th:nth-child(5) > h3 {
    transform: translateX(5px);
}

.userTable tbody td:last-child {
    width: 70px;
}

.userTable tbody td:last-child > div {
    display: inline-flex;
    width: 100%;
    padding-right: 10px;
    padding-left: 10px;
}

.roleDropdown {
    width: 133px !important;
    margin-top: 2px;
}

.roleDropdown div:nth-child(1) > div:first-child {
    color: #0C615A;
    font-size: 14px;
    font-weight: 400;
    text-transform: uppercase;
}

.removeUserButton {
    width: 40px;
    height: 40px;
}

.removeUserButton svg {
    transform: translateX(-5px) scale(1.2);
}

.lockIcon {
    display: flex;
    align-items: center;
    justify-content: center;
    min-width: 40px;
    min-height: 40px;
}

.plusIcon {
    transform: scale(0.6);
    margin-left: -6px;
}

.userRoleLabel {
    color: #808080;
    font-size: 14px;
    
    text-transform: uppercase;
    width: 133px;
    padding-left: 20px;
    margin-bottom: -3px;
}