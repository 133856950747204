.pageBackground{
    height: 100%;
    background-color: #E3EBE9;
}

.container {
    padding-bottom: 25px;
}

.container > h2 {
    margin-bottom: 24px;
}

.fileIcon {
    width: 20px !important;
}

.fileSwitchButtons {
    display: flex;
    align-items: center;
    gap: 16px;
    background-color: #E3EBE9;
    width: fit-content;
    padding: 4px 6px;
    border-radius: 10px;
}

.fileSwitchButtons button
{
    border-radius: 10px;
    width: 115px;
    justify-content: center;
    padding: 0;
    color: #414141;
}

.fileSwitchButtons button:hover {
    background-color: #0000000D;
    color: #414141;
}

.fileSwitchButtons .active {
    background-color: #FFFFFF;
    color: #05534C;
    pointer-events: none;
}

.fileSwitchSelected {
    margin: 4px 28px 0;
    border-bottom: 4px solid ;
    padding: 3px 2px;
}

.searchInput {
    height: 40px;
    width: 152px;
}