@value vtpFormCardRow from "../base/vtpFormCard.module.css";

.subscriptionInfoCard {
    margin-bottom: 32px;
}

/*============ Header ============*/
.subscriptionInfoCardHeader {
    min-height: 88px;
    display: flex;
    align-items: center;
    padding: 0 24px 0 32px;
    cursor: pointer;
}

.subscriptionNameEditButton {
    transform: translateY(-3px);
}
.subscriptionNameEditButton svg {
    width: 17px;
    transform: translate(0, -1px);
}

.subscriptionInfoCardHeader > div:last-child {
    display: flex;
    margin-left: auto;
    gap: 32px;
    align-items: center;
}

.subscriptionNameInput {
    transform: translateY(1px);
}

.subscriptionReference {
    position: absolute;
    transform: translateY(-6px);
    color: #787878;
    font-size: 13px;
    font-family: Mulish;
}

/*============ Activity history ============*/
.subscriptionActivityContainer {
    padding: 0 0 24px 32px;
    display: flex;
    flex-direction: column;
}

.subscriptionActivityRow {
    display: inline-flex;
    gap: 15px;
    align-items: center;
}

.subscriptionActivityRow + .subscriptionActivityRow {
    margin-top: 8px;
}

.subscriptionActivityPurchaseLabel {
    display: inline-flex;
    align-items: center;
    justify-content: center;
    width: 86px;
    height: 30px;
    background: #E1F9E8;
    border-radius: 30px;
    color: #5AAA5F;
}

.batchInfoLink {
    cursor: pointer;
}

.batchInfoLink:hover{
    color: #0a4e48;
}

/*============ Subscription details ============*/
.subscriptionDetails {
    padding: 0 32px 0 32px;
}

.subscriptionDetailsEditForm {
    padding-left: 32px;
}

.subscriptionDetailsEditFormRow {
    display: inline-flex;
    width: 100%;
    justify-content: space-between;
    align-items: center;
}

.subscriptionDetailsEditFormRow:nth-child(1){
    margin-top: 16px;
}

.subscriptionDetailsEditFormRow:nth-child(2){
    margin-top: 6px;
}

.subscriptionDetailsEditFormRow:nth-child(3){
    margin-top: 16px;
}

.subscriptionDetailsEditFormRow:nth-child(4){
    margin-top: 17px;
}

.subscriptionDetailsButtons {
    width: 100%;
    display: inline-flex;
    justify-content: end;
    gap: 16px;
    margin-top: 32px;
    margin-bottom: 16px;
}

.subscriptionDetailsSaveButton {
    display: inline-flex;
    justify-content: center;
    width: 129px;
    height: 40px;
}

.cardDetails {
    display: flex;
    align-items: center;
    gap: 16px;
}

.cardDetails svg {
    margin-top: -4px;
}

.customerPortalLinkSection {
    display: flex;
    width: 137px;
    justify-content: center;
}

.customerPortalLink {
    display: inline-flex;
    align-items: center;
    color: #0C615A;
    text-decoration: none;
    gap: 8px;
    cursor: pointer;
}

.customerPortalLink:hover {
    color: #0A4E48;
}

.customerPortalLink svg path{
    stroke: #0C615A;
}

.customerPortalLink:hover svg path {
    stroke: #0A4E48;
}