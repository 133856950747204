.search span {
    color: #808080;
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 24px;
}

.search {
    max-width: 142px;
    height: 40px !important;
    border-color: #BCD4CC !important;
    border-radius: 10px !important;
    border-width: 2px !important;
}

.search:focus-within {
    border: solid 2px #0C615A;
}

.search svg {
    transform: scale(1.1) translateX(8px);
}