@value ui: "../../styles/ui.module.css";

@value text: "../../styles/text.module.css";

@value panels: "../../styles/panels.module.css";

@value defaultFont, defaultStyle, vtpPrimaryColor, vtpStatesHover, vtpStatesHoverShade, vtpStatesHoverShadeBorder, vtpStatesInactive, vtpSecondPanelContrastColor from ui;

@value vtpPanelWhiteColor from panels;

.buttonContainer {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 0;
    gap: 10px;
}

.buttonSmall,
.buttonMedium,
.buttonLarge {
    display: flex;
    align-items: center;
    text-align: center;
    width: auto;
}

.buttonSmall {
    padding: 8px 15px;
    height: 40px;
    border-radius: 33.5px;
}

.buttonMedium {
    padding: 9px 30px;
    height: 48px;
    border-radius: 33.5px;
}

.buttonLarge {
    padding: 10px 40px;
    height: 56px;
    border-radius: 33.5px;
}

.buttonPrimaryType,
.buttonPrimaryType:focus{
    background: vtpPrimaryColor;
    color: vtpPanelWhiteColor;
    border-color: vtpPrimaryColor;
    box-shadow: none !important;
    outline: none !important;
}

.buttonPrimaryType:hover {
    background: vtpStatesHover;
    border-color: vtpStatesHover;
    color: vtpPanelWhiteColor;
}

.buttonPrimaryType:active,
.buttonPrimaryType:active:focus {
    background: vtpSecondPanelContrastColor !important;
    border-color: vtpSecondPanelContrastColor !important;
    box-shadow: none !important;
    outline: none !important;
    color: vtpPanelWhiteColor !important;
}

.buttonPrimaryTypeInactive {
    background: vtpStatesInactive !important;
    border-color: vtpStatesInactive !important;
    color: vtpPanelWhiteColor !important;
}

.buttonSecondaryType,
.buttonSecondaryType:focus {
    border: 2px solid vtpPrimaryColor;
    color: vtpPrimaryColor;
    background: none;
    box-shadow: none !important;
    outline: none !important;
}

.buttonSecondaryType:hover {
    background: vtpStatesHoverShade;
    border-color: vtpStatesHoverShadeBorder;
    color: vtpPrimaryColor;
}

.buttonSecondaryType:active,
.buttonSecondaryType:active:focus {
    background: vtpSecondPanelContrastColor !important;
    border-color: vtpSecondPanelContrastColor !important;
    color: vtpPrimaryColor !important;
    box-shadow: none !important;
    outline: none !important;
}

.buttonSecondaryTypeInactive {
    border: 2px solid vtpStatesInactive !important;
    color: vtpStatesInactive !important;
}

.buttonTertiaryType,
.buttonTertiaryType:focus {
    border: none;
    background: none;
    color: vtpPrimaryColor;
    box-shadow: none !important;
    outline: none !important;
}

.buttonTertiaryType:hover {
    background: vtpStatesHoverShade;
    border-color: vtpStatesHoverShadeBorder;
    color: vtpPrimaryColor;
}

.buttonTertiaryType:active,
.buttonTertiaryType:active:focus {
    background: vtpSecondPanelContrastColor !important;
    border-color: vtpSecondPanelContrastColor !important;
    color: vtpPrimaryColor !important;
    box-shadow: none !important;
    outline: none !important;
}

.buttonTertiaryTypeInactive {
    color: vtpStatesInactive !important;
    border: none;
}

/* ==== Icon overrides for sizes and types ==== */
.buttonSmall svg {
    height: 20px;
    width: 20px;
}

.buttonMedium svg {
    height: 24px;
    width: 24px;
}

.buttonLarge svg {
    height: 28px;
    width: 28px;
}

.buttonPrimaryType svg path,
.buttonPrimaryType svg {
    fill: vtpPanelWhiteColor;
}

.buttonSecondaryType svg path,
.buttonSecondaryType svg,
.buttonTertiaryType svg path,
.buttonTertiaryType svg {
    fill: vtpPrimaryColor;
}

.buttonSecondaryTypeInactive svg path,
.buttonSecondaryTypeInactive svg,
.buttonTertiaryTypeInactive svg path,
.buttonTertiaryTypeInactive svg {
    fill: vtpStatesInactive;
}
