.vtpTable {
  width: 100%;
  /*table-layout: fixed;*/
}

.vtpTable tr {
  height: 26px;
  max-height: 62px;
}

.vtpTable tbody tr:hover {
  background-color: #f7f9f9;
}

.vtpTable tbody > tr:not(:first-child) {
  border-top: 1px solid #C4C4C4;
}

.vtpTable tbody > tr:last-child {
  border-bottom: 1px solid #C4C4C4;
}

.vtpTable thead {
  height: 38px;
}

.vtpTable thead th {
  background-color: #E3EBE9;
  vertical-align: middle;
}

.vtpTable thead th:first-child {
  border-radius: 10px 0 0 10px;
}
.vtpTable thead th:last-child {
  border-radius: 0 10px 10px 0;
}

.vtpTable thead th h3 {
  margin: 0;
  color: #414141;
}

.vtpTable td:first-child,
.vtpTable th:first-child {
  padding-left: 10px;
}

.vtpTable td {
  height: 53px;
  vertical-align: center;
}

.vtpTableFocusedRow {
  position: sticky;
}

.container {
  position: relative;
}

.loadingContainer {
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  padding-top: 40px;
  pointer-events: none;
}

.loadingBackdrop {
  background: rgba(255, 255, 255, 0.80);
  backdrop-filter: blur(4px);
  height: 100%;
  width: 100%;

  display: flex;
  justify-content: center;
  align-items: center;

  padding-top: 30px;
  padding-bottom: 20px;
}

.loadingSpinner {
  height: 100%;
  min-height: 50px;
  min-width: 50px;
  max-height: 108px;
  max-width: 108px;
}

.loadingSpinner svg {
  animation: rotate 0.85s linear infinite;
  width: 100%;
  height: 100%;
}

@keyframes rotate{
  to{ transform: rotate(360deg); }
}
