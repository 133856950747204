.container {
}

.inputContainer {
    display: flex;
    flex-direction: column;
    justify-content: center;
    height: 25px;
    
    position: relative;
    padding-left: 33px;
    margin-bottom: 8px;
    cursor: pointer;
    font-size: 22px;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
}

/* Hide the browser's default radio button */
.inputContainer input {
    position: absolute;
    opacity: 0;
    cursor: pointer;
}

/* Create a custom radio button */
.checkmark {
    position: absolute;
    top: -1px;
    left: 0;
    height: 25px;
    width: 25px;
    border: 2px solid #E3EBE9;
    border-radius: 50%;
}

/* On mouse-over, add a grey background color */
.inputContainer:hover input ~ .checkmark {
    background-color: #c6c6c6;
    border-color: #0C615A;
}

/* When the radio button is checked, add a blue background */
.inputContainer input:checked ~ .checkmark {
    border-color: #0C615A;
}

/* Style the indicator (dot/circle) */
.inputContainer input:checked ~ .checkmark:after {
    content: "";
    position: absolute;
    display: block;
    
    top: 17%;
    left: 17%;
    width: 14px;
    height: 14px;
    border-radius: 50%;
    background: #0C615A;
}

.checkedLabel {
    color: #414141
}

.disabled {
    pointer-events: none;
}