.modalContainer :global(.modal-content) {
  padding: 32px !important;
}

.inviteUsersListContainer {
  padding: 0;
  z-index: 0;
  display: flex;
  flex-direction: column;
  height: 100%;
}

.inviteUsersList {
  margin-top: 18px;
  width: auto;

  display: flex;
  flex-direction: column;
  padding-top: 7px;
  padding-bottom: 2px;

  overflow-y: auto;
}

/* === List item === */
.inviteUsersListItem {
  display: flex;
  height: 29px;
  width: auto;
  align-items: center;
}

.inviteUsersListItem + .inviteUsersListItem {
  margin-top: 10px;
}

.inviteUsersListItemUserIcon {
  margin-right: 16px;
}

.inviteUsersListItemUserIcon > svg {
  width: 24px;
  height: 24px;
}

.inviteUsersListItemEmail {
  height: 26px;
  max-width: 390px;
  padding-top: 1px;
  width: 100%;

  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
}

.inviteUsersListItemCloseIcon {
  cursor: pointer;
}

.inviteUsersListItemCloseIcon > svg {
  width: 30px;
  height: 30px;
}

.inviteUserPlusButton {
  margin-right: 8px;
  cursor: pointer;
  width: 40px;
  height: 36px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
}

.inviteUserPlusButton:hover {
  background-color: #CCCCCC;
}

.inviteUserPlusButton:active {
  background-color: #BCD5CC;
}

.inviteUserPlusButton svg {
  width: 13px;
}

@media (max-width: 400px) {
  .inviteUsersListItemEmail {
    max-width: 100px;
  }
}

/* === Can do later info box === */
.inviteUsersListCanDoLaterInfo {
  margin-top: 29px;
  background-color: #e3ebe9;
  border-radius: 12px;
  width: 254px;
  height: 102px;

  padding: 11px 19px 16px 15px;
  margin-bottom: 51px;
  display: flex;
  flex-direction: row;
}

.inviteUsersListCanDoLaterInfo > svg {
  width: 24px;
  height: 24px;
  margin-right: 11px;
}

.inviteUsersListCanDoLaterInfo > span {
  font-family: "Mulish";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  color: #414141;
  width: 185px;
  height: 75px;
}

.inviteUsersList::-webkit-scrollbar {
  width: 13px;
}

.inviteUsersList::-webkit-scrollbar-track {
  background: #d0e2e2;
  border-radius: 5px;
}

.inviteUsersList::-webkit-scrollbar-thumb {
  background: #0c615a;
  border-radius: 9px;
}

.inviteUsersListInviteButton {
  padding-right: 8px;
  cursor: pointer;
}

.inviteUsersListInviteButton:hover > svg > circle {
  fill: #0a4e48;
}
