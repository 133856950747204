.courseCard {
    min-height: 82px;
}

.courseCardHeader {
    display: flex;
    align-items: center;
    justify-content: end;
    gap: 4px;
    padding: 22px 28px 20px 32px;
}

.courseCardHeader.active {
    cursor: pointer;
}

.courseName {
    margin-right: auto;
    display: flex;
    flex-direction: column;
    gap: 7px;
    color: #414141;
}

.courseDescription {
    margin-top: -8px;
}

.courseMembersLabel{
    color: #414141;
    width: 109px;
}

.underline {
    border-bottom: 1px solid #BCD4CC;
    width: 50px;
}

.active .underline {
    border-color: #0c615a;
}

.plusIcon {
    transform: scale(0.6);
}

.plusIcon path{
    stroke: #0C615A;
}

/* Table styling */
.tokenTableContainer {
    padding: 0 28px 22px 32px;
}

.tokenPaging {
    margin-top: 24px;
}

.tokenTableContainer tbody td:first-child button{
    transform: translateX(-13px);
}

.tokenTableContainer thead th:last-child {
    width: 150px;
}

.userRoleLabel {
    color: #808080;
    font-size: 15px;
    font-weight: 500;
    width: fit-content;
}

.autoRenewal {
    display: flex;
    justify-content: center;
    padding-right: 15px;
}

.courseCardHeader:not(.active) > span
{
    color: #808080;
}

.analyticsButton {
    width: 40px !important;
}

.analyticsButton svg {
    transform: scale(1.1) translateX(-4px);
}