.invitationModalButton {
    width: 145px;
    justify-content: center;
}

.cancelButton,
.cancelButton:active,
.cancelButton:hover,
.cancelButton:active:focus {
    color: #853232 !important;
}

.cancelButton:active,
.cancelButton:active:focus{
    background-color: #F9E1E1 !important;
}

.cancelButtonSpinner {
    color: #853232 !important;
}