@value vtpModalTitle, vtpModalTitleItems from "./vtpModal.module.css";

.vtpConfirmationModal :global(.modal-dialog) {
    width: 100% !important;
    min-width: 408px !important;
    max-width: 475px !important;
}

.vtpConfirmationModal :global(.modal-dialog) > :global(.modal-content) {
    padding: 30px 34px 34px 34px !important;
}

.vtpConfirmationModal .vtpModalTitle {
    margin-bottom: 16px !important;
}

.vtpConfirmationModal .vtpModalTitle > h1 {
    font-weight: 500  !important;
    font-size: 28px !important;
}

.vtpConfirmationBody {
    white-space: pre-wrap;
}

.vtpConfirmationIcon .vtpModalTitle{
    transform: translateX(-4px);
}

.vtpConfirmationIcon .vtpModalTitleItems svg:first-child{
    margin-right: 14px;
}
