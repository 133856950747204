/* Tooltip container */
.tooltip {
    position: relative;
    display: inline-block;
}

/* Tooltip text */
.tooltip .tooltipText {
    visibility: hidden;
    /*width: 120px;*/
    background-color: rgba(0, 0, 0, 0.6);
    text-align: center;
    padding: 5px 0;
    border-radius: 6px;
    
    position: absolute;
    z-index: 1;
}

.tooltip:hover .tooltipText {
    visibility: visible;
}

/* Tooltip positions */
.tooltipTop .tooltipText {
    bottom: 115%;
    left: 50%;
    transform: translateX(-50%);
}

.tooltipBottom .tooltipText {
    top: 115%;
    left: 50%;
    transform: translateX(-50%);
}

.tooltipLeft .tooltipText {
    top: 50%;
    right: 105%;
    transform: translateY(-50%);
}

.tooltipRight .tooltipText {
    top: 50%;
    left: 105%;
    transform: translateY(-50%);
}

/* Tooltip arrows */
.tooltipTop .tooltipText::after {
    content: " ";
    position: absolute;
    top: 100%;
    left: 50%;
    margin-left: -5px;
    border-width: 5px;
    border-style: solid;
    border-color: rgba(0, 0, 0, 0.6) transparent transparent transparent;
}

.tooltipBottom .tooltipText::after {
    content: " ";
    position: absolute;
    bottom: 100%;
    left: 50%;
    margin-left: -5px;
    border-width: 5px;
    border-style: solid;
    border-color: transparent transparent rgba(0, 0, 0, 0.6) transparent;
}

.tooltipLeft .tooltipText::after {
    content: " ";
    position: absolute;
    top: 50%;
    left: 100%;
    margin-top: -5px;
    border-width: 5px;
    border-style: solid;
    border-color: transparent transparent transparent rgba(0, 0, 0, 0.6);
}

.tooltipRight .tooltipText::after {
    content: " ";
    position: absolute;
    top: 50%;
    right: 100%;
    margin-top: -5px;
    border-width: 5px;
    border-style: solid;
    border-color: transparent rgba(0, 0, 0, 0.6) transparent transparent;
}