@value uiStyles: "../../../styles/ui.module.css";
@value vtpPrimaryColor from uiStyles;
@value textStyles: "../../../styles/text.module.css";
@value vtpSemanticLightError from textStyles;

.vtpTextInputContainer {
    width: 250px;
    height: 58px;
    padding: 0;
    display: flex;
    align-items: center;
}

.vtpTextInputInvalid {
    border-color: #BC4D4D !important;
}

.vtpTextInputContainer:hover{
    background-color: #E3EBE9;
}

.vtpTextInputContainer:focus-within {
    background-color: #E3EBE9;
    border-color: transparent;
}

.vtpTextInputUnderlineBorder{
    border-bottom: solid 3px #0C615A;
    border-top-right-radius: 14px;
}

.vtpTextInputRoundBorder{
    border: solid 2px #0C615A;
    border-radius: 14px;
}

.vtpTextInputGroup {
    position: relative;
    width: 100%;
    height: 100%;
}

.vtpTextInputGroup > input {
    background-color: transparent;
    border: none;
    padding: 16px 14px;
    height: inherit;
    width: 100%;
}

.vtpTextInputGroup > input:focus {
    outline: none;
}

.vtpTextPlaceholderInput {
    position: absolute;
    top: 50%;
    transform: translate(14px,-50%);
    text-transform: none;
    pointer-events: none;
    transition: transform 0.1s linear;
}

.placeholderFloat{
    font-weight: 500;
    font-size: 18px;
    line-height: 130%;
    top: 0;
    transform: translate(11px, -110%);
    text-transform: uppercase;
}

.vtpTextInputError{
    position: absolute;
    font-weight: 400;
    line-height: 26px;
    color: vtpSemanticLightError;

    top: 0;
    transform: translate(11px, 58px);
}

/*==== Form card input styling ====*/
.vtpFormCardTextInput {
    height: 36px;
    width: 233px;
    background-color: #E3EBE9;
    border-radius: 10px;
    border-color: transparent;
}

.vtpFormCardTextInput.inputReadOnly {
    background-color: unset;
    pointer-events: none;

    -webkit-touch-callout: none;
    -webkit-user-select: none;
    -khtml-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
}

.vtpFormCardTextInput input{
    text-align: right;
}

