.container {
    background-color: #0C615A;
    height: 100dvh;
    padding-top: 40px;
    display: flex;
    flex-direction: column;
}

.kandaLogo {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 92px;
    height: 28px;
    margin-left: 39px;
}

.kandaLogo > svg {
    scale: 1.2;
    transform: translateX(-6px);
}

.kandaLogo > svg:last-child {
    transform: translateX(-8px);
}

.userInfo {
    display: flex;
    align-items: center;
    color: #BCD4CC;
    margin-top: 13px;
    margin-left: 41px;
    gap: 8px;
}

.inputCodeWrapper {
    display: flex;
    justify-content: center;
    align-items: center;
    max-height: 627px;
    height: 100%;
}

.inputCodeSection > div {
    max-width: 221px;
    color: #FFFFFF !important;
    text-align: center;
    margin-top: 51px;
}

.inputCodeButton {
    background-color: #FFFFFF;
    color: #0C615A;
    padding-left: 30px;
}

.inputCodeButton svg path {
    fill: #0c605a;
}

.inputCodeButton:hover svg path{
    fill: #FFFFFF;
}

.logoutButton {

    margin-top: auto;
    margin-left: 29px; /* Adjust the margin as needed */
    margin-bottom: 42px;
}

.invitationNotification {
    width: unset;
}
