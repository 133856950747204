.container {
    display: flex;
    gap: 8px;
    justify-content: center;
}

.pagingInfo {
    padding: 3px 8px;
    display: flex;
    align-items: center;
    justify-content: center;
    /*background-color: yellow;*/
    background-color: #0000001A;
    border-radius: 5px;
    color: #414141;
}

.previousPageIcon {
    transform: rotateY(180deg);
}

.pageButton {
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 5px;
    height: 30px;
    width: 30px;
    cursor: pointer;
}

.pageButton:hover {
    background-color: #0000001A;
}

.pageButton:active {
    background-color: #00000033;
}

.disabled {
    cursor: none;
    pointer-events: none;
}

.disabled svg path{
    fill: #a5a5a5;
    /*display: none;*/
}
