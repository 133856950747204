.container :global(.rs-input-group),
.container :global(.rs-input-group):hover,
.container :global(.rs-input-group):active{
    border: #0C615A solid 2px;
    border-radius: 10px;
    outline: unset;
}

.container :global(.rs-input-group) input {
    height: 40px;
    font-family: Mulish;
    font-size: 16px;
    font-weight: 500;
}

.container :global(.rs-input-group) svg {
    transform: scale(0.9) translateX(-6px) translateY(-1px);
}

.container :global(.rs-input-group):focus-within {
    border: #0C615A solid 2px;
    border-radius: 10px;
    outline: unset;
}

:global(.rs-picker-popup) {
    margin-top: 12px;
    border: #0C615A solid 2px;
    border-radius: 10px;
}

:global(.rs-picker-popup .rs-calendar:first-child) {
    border-right-color: transparent;
}

:global(.rs-calendar) + :global(.rs-calendar)::before {
    position: absolute;
    content: "";
    display: block;
    border-left: #0C615A solid 1px;
    height: 250px;
}

:global(.rs-calendar-header-month-toolbar .rs-btn-icon) svg {
    transform: scale(2);
    fill: #0C615A;
}

.container input {
    border-radius: 10px;
}

:global(.rs-calendar-table-cell){
    border-radius: 100%;
}

:global(.rs-calendar-table-cell-in-range) {
    /*position: unset;*/
}

:global(.rs-calendar-table-cell-in-range::before) {
    background-color: #E3EBE9;
    margin-top: 1px;
    height: 30px;
}

:global(.rs-calendar-table-cell-in-range):has(+ :global(.rs-calendar-table-cell-in-range))::before,
:global(.rs-calendar-table-cell-in-range):has(+ :global(.rs-calendar-table-cell-selected))::before {
    padding-right: 50px;
}

:global(.rs-calendar-table-cell-in-range) + :global(.rs-calendar-table-cell-in-range)::before,
:global(.rs-calendar-table-cell-selected) + :global(.rs-calendar-table-cell-in-range)::before {
    transform: translateX(-9px) scaleX(1.55);
}


:not(:global(.rs-calendar-table-cell-un-same-month)) > :global(.rs-calendar-table-cell-content) {
    color: #696969 !important;
}

:global(.rs-calendar-table-cell-content) {
    font-family: Mulish;
    font-weight: 500;
}

:global(.rs-calendar-table-cell-is-today) :global(.rs-calendar-table-cell-content) {
    box-shadow: none;
}

/*:global(.rs-picker-toolbar) {*/
/*    display: none;*/
/*}*/


:global(.rs-calendar-table-cell):hover,
:global(.rs-calendar-table-cell-content):hover
{
    background-color: #D0E0DB !important;
    border-radius: 100% !important;
    z-index: 1;
}

:global(.rs-calendar-table-cell-selected) > :global(.rs-calendar-table-cell-content) {
    background-color: #BCD4CC;
    border-radius: 100%;
}

:global(.rs-calendar-table-cell:hover) :global(.rs-calendar-table-cell-content){
    background-color: unset !important;
    border-radius: 100% !important;
}

/* Styles appearance of days of the weeks names. */
:global(.rs-calendar-table-header-cell-content){
    visibility:visible;
    font-family: Mulish;
    font-size: 13px;
    font-weight: 500;
}

/*Formatting month picker text*/ 
:global(.rs-calendar-header-title-date){
    font-family: Mulish;
    font-size: 14px;
    font-weight: 500;
}

/* Styling toolbar ok button */
:global(.rs-picker-toolbar-right) button,
:global(.rs-picker-toolbar-right) button:focus{
    text-indent: -9999px;
    line-height: 0; /* Collapse the original line */

    padding: 6px 15px;
    border-radius: 33.5px;
    background-color: #0C615A;
    border-color: #0C615A;
    color: #FFFFFF;
    
    font-family: Mulish;
    font-weight: 500;
}

:global(.rs-picker-toolbar-right) button:hover{
    background: #0A4E48;
    border-color: #0A4E48;
    color: #FFFFFF;
}

:global(.rs-picker-toolbar-right) button:active,
:global(.rs-picker-toolbar-right) button:active:focus {
    background: #D0E2E2;
    border-color: #D0E2E2;
    box-shadow: none !important;
    outline: none !important;
    color: #FFFFFF;
}

:global(.rs-picker-toolbar-right) button:disabled {
    background-color: #C4C4C4;
}

:global(.rs-picker-toolbar-right) button::after {
    content: "Apply";
    text-indent: 0;
    display: block;
    line-height: initial; /* New content takes up original line height */
}