.almostThereContainer {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    padding: 54px 20px;
}

.almostThereTenantList {
    margin-top: 40px;
    margin-bottom: 40px;
    max-width: 350px;
    max-height: 185px;
    /*overflow-x: scroll;*/
    overflow-x: auto;
    font-family: 'Mulish';
    font-style: normal;
    font-weight: 400;
    font-size: 18px;
    line-height: 26px;
    color: #414141;
    display: flex;
    align-items: center;
    font-feature-settings: 'tnum' on, 'lnum' on;
    scrollbar-width: auto;
    scrollbar-color: #0c615a #d0e2e2;
}

.almostThereTenantList::-webkit-scrollbar {
    width: 13px;
}

.almostThereTenantList::-webkit-scrollbar-track {
    background: #d0e2e2;
    border-radius: 5px;
}

.almostThereTenantList::-webkit-scrollbar-thumb {
    background: #0c615a;
    border-radius: 9px;
}

.almostThereTenantOption {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding-right: 17px;
    width: 100%;
    min-height: 53px;
    cursor: pointer;
    color: #414141;
}

.almostThereTenantOption:hover {
    background-color: #bcd5cc;
}

.almostThereSeparatorContainer {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: row;
}

.almostThereSeparator {
    background-color: #414141;
    border: 1px solid #414141;
    width: 160px;
}

.almostThereSeparatorText {
    font-family: 'Mulish';
    font-style: normal;
    font-weight: 400;
    font-size: 18px;
    line-height: 26px;
    display: flex;
    align-items: center;
    text-align: center;
    font-feature-settings: 'tnum' on, 'lnum' on;
}

.almostThereAdditionalButtonContainer {
    display: flex;
    justify-content: center;
    margin-top: 20px;
}

.almostThereAddOrganizationButton {
    background-color:  #FFFFFF;
    color: #147870;
}
.almostThereAddOrganizationButton:hover {
    background-color:  #FFFFFF;
    color: #147870;
}
