.container {
    padding: 32px 64px 16px 64px;
    width: 826px;
}

.container table {
    margin-top: 24px;
}

.container td {
    height: 68px;
    vertical-align: middle;
    padding-bottom: 0;
}

.container th:first-child {
    padding-left: 0;
    width: 226px;
}

.container th:nth-child(2){
    width: 143px;
}

.container th:nth-child(3){
    width: 180px;
}

.container td:nth-child(3){
    white-space: nowrap;
}

.container th:last-child {
    text-align: center;
    white-space: nowrap;
}

.container th:last-child,
.container td:last-child {
    padding-right: 0;
}

.container td:first-child {
    padding-left: 0;
}

.container td:nth-child(3){
    text-transform: uppercase;
}

.container tr:last-child {
    border-bottom: 2px solid #e3ebe9;;
}

.buttonSection {
    display: inline-flex;
    width: 100%;
    justify-content: center;
    margin-top: 16px;
}

.buttonSection > button {
    padding-left: 45px;
    padding-right: 45px;
}

.userEmailCell svg {
    width: 24px;
    height: 24px;
    margin-right: 9px;
    transform: translateY(-1px);
}
