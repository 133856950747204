@value vtpPanelWhiteColor: #FFFFFF;
@value vtpPanelInactivePanelColor: #EEEEEE;
@value vtpPanelVirtualTagColor: #00000066;   /* #000000 40% */

.whiteColor {
    color: vtpPanelWhiteColor;
}

.inactiveColor {
    color: vtpPanelInactivePanelColor;
}

.virtualTagColor {
    color: vtpPanelVirtualTagColor;
}

