.container {
    width: 608px;
    height: 763px;
    padding: 32px 0;
    display: flex;
    flex-direction: column;
}

.header {
    padding-left: 64px;
}

.uploadSection {
    display: flex;
    flex-direction: column;
    gap: 8px;
    padding: 0 64px;
    margin-top: 32px;
    margin-bottom: 15px;
}

.filePicker {
    display: flex;
    flex-direction: column;
    gap: 16px;
    
    width: 479px;
    height: 156px;
    justify-content: center;
    align-items: center;
    
    border: 2px #BCD4CC dashed;
    border-radius: 10px;
}

.filePicker button{
    border-radius: 10px;
}

/* Content */
.modalContent{
    overflow-y: auto;
    margin-right: 24px;
}

.fileList {
    padding-left: 64px;
    width: 550px;
    height: 310px;
}

.fileListRow {
    display: inline-flex;
    width: 100%;
    height: 52px;
    align-items: center;
    position: relative;
}

.dropdown {
    width: 130px;
    margin-left: auto;
    min-width: 130px;
}

.dropdown *{
    text-align: center;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
}

.dropdown > div:nth-child(1) > *{
    color: #0C615A !important;
}

.fileIconColumn {
    margin-right: 9px;
    display: flex;
    width: 24px;
}

.fileNameColumn {
    /*width: 100%;*/
    /*margin-right: 5px;*/
}

.fileName {
    width: 170px;
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
}

.availabilityLabel {
    text-align: center;
    width: 80px;
}

.errorMessage {
    width: 314px;
    color: #BC4D4D;
}

.sizeColumn {
    text-align: right;
    width: 75px;
    margin-left: auto;
    white-space: nowrap;
}

.modalContent::-webkit-scrollbar {
    width: 13px;
}

.modalContent::-webkit-scrollbar-track {
    background: #d0e2e2;
    border-radius: 5px;
}

.modalContent::-webkit-scrollbar-thumb {
    background: #0c615a;
    border-radius: 9px;
}

.kandaLogo {
    margin-left: 64px;
    margin-right: 40px;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 310px;
    overflow: hidden;
}

.kandaLogo > svg {
    width: 280px;
}

.buttonGroup {
    display: flex;
    align-items: center;
    width: 90px;
    justify-content: end;
}

.fileRowButton {
    height: 35px;
    width: 35px;
}

.uploadIcon {
    width: 18px !important;
}

.fileRowIcon {
    transform: translateX(-7px) scale(1.1);
}

/* Footer */
.modalButtons {
    margin: auto 64px 0 64px;
    border-top: 1px solid #D0E2E2;
    padding-top: 16px;
    display: flex;
    gap: 28px;
    justify-content: end;
}

/* Text input override */
.fileNameInput {
    height: 40px;
    width: 185px;
    transform: translateX(-6px);
    border-color: transparent;
}

.fileNameInput:hover {
    border-color: #0C615A;
}

.fileNameInput input{
    padding-left: 6px;
    padding-right: 8px;
    
    font-family: Mulish;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
}
