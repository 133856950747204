.header {
    background-color: #E3EBE9;
    padding: 0 64px 16px;
}

.headerInputs {
    max-width: 913px;
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.uploadIcon {
    width: 15px !important;
}

.footer {
    background-color: #FFFFFF;
    padding: 0 64px;
}

.tableContainer {
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: center;
    height: 100%;
    box-sizing: border-box;
    margin: 0 64px;
}

.tableContainer table {
    table-layout: fixed;
}

.tableContainer tbody:empty {
    height: 55px;
}

.tableContainer thead th:nth-child(1) {
    width: 40%;
}

.tableContainer thead th:nth-child(2) {
    width: 10%;
}

.tableContainer thead th:nth-child(3)
{
    width: 10%;
}

.tableContainer thead th:nth-child(4)
{
    width: 11%;
}

.tableContainer thead th:nth-child(5) {
    width: 25%;
}

.tableContainer thead th:last-child,
.tableContainer tbody td:last-child{
    width: 4%;
}

.tableContainer tbody td:first-child{
    transform: translateX(-15px);
}


.tableContainer > div:last-child{
    margin-top: 29px;
}

.sharedIconColumn {
    display: flex;
    justify-content: center;
    width: 60px;
}

.privateUserFileIcon {
    width: 24px;
    height: 24px;
    transform: scale(1.15);
}

.sharedUserFileIcon {
    width: 31px;
    height: 22px;
    transform: scale(1.15);
}

.userFileContextMenu {
}

.userFileContextMenu > svg {
    margin: 0 0 0 0;
}

.downloadIcon{
    width: 16px !important;
    transform: translateX(-1px);
}

.fileInfoColumn {
    max-width: 100%; /* Ensure it doesn't grow beyond the cell */
    display: flex;
    align-items: center;
    gap: 9px;
    padding: 6px 10px;
    overflow: hidden; /* Add this to handle overflow */
    width: fit-content;
    
    
    border-radius: 33px;
    /*margin-right: 15px;*/
    cursor: pointer;
}

.fileInfoColumn svg {
    transform: translateY(-2px);
}

.fileInfoColumn:hover {
    background: #00000033;
    outline-color: #00000033;
}

.fileInfoColumn:hover path {
    fill: #0c615a;
}

.fileInfoColumn:active {
    background: #D0E2E2;
    outline-color: #D0E2E2;
}


.uploadedByColumn{
    max-width: 200px;
    overflow: hidden;
    text-overflow: ellipsis;
}

.fileIcon {
    
}

.fileName {
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    padding-right: 8px;
}

.uploadedByColumnHeader {
    padding-bottom: 5px;
}

.fileLoadingIcon  {
    transform: scale(0.65) translateX(-11px) translateY(3px);
    color: #0c605a;
}

.fileLoadingIcon > * {
    animation-duration: 1s;
}