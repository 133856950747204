input[type="checkbox"].vtpCheckbox {
    appearance: none;
    background-color: #fff;
    margin: 0;

    height: 25px;
    width: 25px;
    border: 2px solid #E3EBE9;
    border-radius: 4px;
    display: grid;
    place-content: center;
    cursor: pointer;
}

input[type="checkbox"].vtpCheckbox:checked {
    border: 2px solid #0A4E48;
}

input[type="checkbox"].vtpCheckbox:hover {
    background: rgba(0, 0, 0, 0.2);
    border: 2px solid #0A4E48;
}

input[type="checkbox"].vtpCheckbox::before {
    content: "";
    width: inherit;
    height: inherit;
    
    transform: scale(0);
    background-image: url("../../../assets/icons/checkmark.svg");
    background-repeat: no-repeat;
    background-position: center;
}

input[type="checkbox"].vtpCheckbox:checked::before {
    transform: scale(1);
}