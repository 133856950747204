.container {
    padding: 32px;
    width: 500px;
}

.modalInput {
    width: 280px;
}

.modalInputGroup {
    margin-top: 24px;
}

.modalInputGroup > div:first-child {
    margin-bottom: 15px;
}

.modalButtons {
    margin-top: 24px;
    display: flex;
    gap: 32px;
    justify-content: end;
}

.datePicker {
    outline: none;
    height: 50px;
    border: #0c615a 2px solid;
    border-radius: 10px;
    padding: 0 10px;
}