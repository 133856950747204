.vtpAlertContainer{
    position: fixed;
    bottom: 1px;
    right: 20px;
    max-width: 620px;
    z-index: 2000;
    width: 100%;
    padding-left: 40px;
}

.vtpAlert{
    background: rgba(128, 128, 128, 0.7);
    border-radius: 13px;
    border-color: transparent;
    width: 100%;
    color: #ffffff;
    padding: 16px;
    transition: transform 0.5s ease, opacity 0.5s ease;
    transform: translateX(0);
    opacity: 1;
}

.vtpAlert.closed {
    transform: translateX(100%);
    opacity: 0;
    pointer-events: none;
}

.vtpAlertTop {
    display: inline-flex;
    flex-direction: row;
}

.vtpAlertTopIcon {
    width: 40px;
    margin-right: 16px;
}

.vtpAlertTopIcon > svg {
    width: 40px;
    height: 40px;
}

.vtpAlertTopMessageContainer{
    display: flex;
}

.vtpAlertTopMessage{
    margin-right: 53px;
    align-content: center;
    max-width: 434px;
    width: 100%;

    font-family: 'Mulish';
    font-style: normal;
    font-weight: 400;
    font-size: 18px;
    line-height: 26px;
    
    margin-top: auto;
    margin-bottom: auto;
}

/*=== Bottom section ===*/
.vtpAlertBottom {
    width: 100%;
    height: 20px;
    margin-top: 16px;
    margin-left: 56px;
}

.vtpAlertBottomErrorCode {
    font-family: 'Mulish';
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
}

/*=== Button/action group ===*/
.alertActionGroup{
    display: flex;
    gap: 16px;
    margin-top: 5px;
    justify-content: end;
}

.alertAction {
    font-family: Mulish;
    font-size: 14px;
    font-weight: 500;
    line-height: 18px;
    letter-spacing: 0em;
    text-align: left;
    cursor: pointer;
    text-transform: uppercase;
}

/* Overriding bootstrap border around active button for the alert */
.vtpAlert > button {
    /*filter: none;*/
}

.vtpAlert > button:focus {
    outline: none !important;
    box-shadow: none;
}

.infoIcon {
    padding: 3px;
}

.infoIcon path {
    fill: #FFFFFF;
}