@value defaultFont: 'Mulish';
@value defaultStyle: normal;

/*VTP UI Elements */
@value vtpPrimaryColor #0C615A;
@value vtpSecondaryColor #BCD4CC;
@value vtpPrimaryPanelContrastColor #E3EBE9;
@value vtpSecondPanelContrastColor #D0E2E2;
@value vtpAccentColor #71E77D;

@value vtpStatesInactive #C4C4C4;
@value vtpStatesHoverShade #00000033;   /* #000000 20% */
@value vtpStatesHoverShadeBorder #0000;
@value vtpStatesSelectedShade #0000001A;    /* #000000 10% */
@value vtpStatesHover #0A4E48;

.primaryColor { 
    color: vtpPrimaryColor;
}

.secondaryColor {
    color: vtpSecondaryColor;
}

.primaryPanelContrastColor {
    color: vtpPrimaryPanelContrastColor;
}

.primaryPanelContrastBackgroundColor {
    background-color: vtpPrimaryPanelContrastColor;
}

.secondPanelContrastColor {
    color: vtpSecondPanelContrastColor;
}

.secondPanelContrastBackgroundColor {
    background-color: vtpSecondPanelContrastColor;
}

.accentColor {
    color: vtpAccentColor;
}

.statesInactiveColor {
    color: vtpStatesInactive;
}

.statesHoverShadeColor {
    color: vtpStatesHoverShade;
}

.statesSelectedShadeColor {
    color: vtpStatesSelectedShade;
}

.statesHoverColor {
    color: vtpStatesHover;
}