@value textStyles: "../../styles/text.module.css";
@value vtpSecondaryColor from textStyles;

.translationButtons {
    background-color: #e2ebe9;
    
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 10px 0;
    margin-bottom: -50px;
}

.translationButtons > div:first-child {
    display: flex;
    gap: 5px;
    justify-content: center;
}

.translationButtons span{
    background-color: white;
    border: 1px solid;
    padding: 5px;
    cursor: pointer;
}

.sidebarContainer {
}

.sidebar {
    display: flex;
    flex-direction: column;
    position: relative;
    width: 272px;
    flex: 0 0 auto;
    background-color: #0C615A;
    padding: 40px 0 31px;
    height: 100%;
    z-index: 2;
    overflow-y: auto;
}

.sidebarLogo {
    display: flex;
    width: 92px;
    height: 36px;
    align-items: center;
    justify-content: center;
    margin-left: 39px;
    transition: margin-top 0.2s;
}

.sidebarLogo > svg:last-child {
    scale: 1.3;
}

.sidebarItem {
    border-radius: 5px;
    display: flex;
    align-items: center;

    background-color: unset;
    color: #BCD4CC;
    width: 100%;
    height: 100%;
    text-decoration: none;
    transition: background-color 0.3s ease, color 0.3s ease;
}

.sidebarItem:hover{
    background: rgba(0, 0, 0, 0.10);
    color: #FFFFFF;
}

.sidebarItem:active,
.sidebarItem.selected
{
    background: rgba(0, 0, 0, 0.20);
}

.sidebarItem.selected {
    color: #71E77D;
}

.tenantPicker {
    cursor: pointer;
    margin-top: 60px;
    margin-left: 24px;
    gap: 8px;
    color: #FFFFFF;
    
    width: 224px;
    height: 56px;
    padding: 16px 20px 16px 16px;
    background: rgba(255, 255, 255, 0.10);

    transition: margin-top 0.2s;
}

.tenantPicker.selected {
    color: #FFFFFF;
}

.appNavigation {
    display: flex;
    flex-direction: column;
    width: 200px;
    margin-left: 48px;
    gap: 16px;
    margin-top: 23px;
    
    transition: all 0.2s;
}

.appNavigation > .sidebarItem {
    padding: 0 20px 0 16px;
    width: 200px;
    height: 40px;
}

.userNavigation {
    display: flex;
    flex-direction: column;
    gap: 16px;
    margin-left: 32px;
    margin-top: auto;

    transition: gap 0.2s;
}

.userNavigation > .sidebarItem {
    display: flex;
    gap: 8px;
    padding: 0 10px 0 8px;
    width: 208px;
    height: 36px;
    color: #E3EBE9;
    cursor: pointer;
}

.userNavigation > .sidebarItem > svg:nth-child(2) {
    margin-left: auto;
}

.userNavigation > .sidebarItem.selected {
    color: #E3EBE9;
}


/*  Options bar  */
.optionBarContainer {
    min-width: 220px;
    height: 100%;
    background: #BCD4CC;
    position: absolute;
    top: 0;
    left: 0; /* Position it to the right of the sidebar */
    transition: left 0.3s ease;
    z-index: 1;
    display: flex;
}

.optionBarContainer.open {
    left: 272px;
}

.optionBar {
    color: #414141;
    padding-left: 24px;
    padding-right: 8px;
}

.optionBarHeader {
    height: 24px;
}

.optionBarItems {
    margin-top: 16px;
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    
    width: 100%;
}

.optionBarItems::-webkit-scrollbar {
    height: 8px;
}

.optionBarItems::-webkit-scrollbar-track {
    background: #d0e2e2;
    border-radius: 5px;
}

.optionBarItems::-webkit-scrollbar-thumb {
    background: #0c615a;
    border-radius: 9px;
}

.optionBarItems::-webkit-scrollbar-thumb:horizontal {
    /*margin-right: 20px*/
}

.optionBarItem{
    display: -webkit-box;
    min-height: 32px;
    border-radius: 5px;
    padding: 4px 8px;
    cursor: pointer;
    width: 180px;
    margin-bottom: 8px;

    -webkit-box-orient: vertical;
    overflow: hidden;
    overflow-wrap: break-word;
    white-space: normal;
    -webkit-line-clamp: 3;
}

.optionBarItem:hover {
    background-color: rgba(0, 0, 0, 0.10);
}

.optionBarItem:active {
    background-color: rgba(0, 0, 0, 0.15);
}

.optionBarItem.selected {
    color: #000000;
    pointer-events: none;
}

.optionBarLinkItem {
    display: inline-flex;
    align-items: center;
    justify-content: space-between;
    padding-right: 20px;
}

.optionBarLinkItem svg {
    height: 15px;
}

.optionBarLinkItem svg path {
    fill: #414141;
}

.userOptions {
    margin-top: auto;
    margin-bottom: 48px;
}

.organisationOptions {
    margin-top: 156px;
}

.organisationOptions .optionBarItems {
    height: 93%;
    overflow-x: auto;
    max-width: 545px;
    margin-right: 5px;
}

.optionBarToggleItem {
    display: inline-flex;
    align-items: center;
}

.remoteAccessToggleGroup {
    padding-left: 8px;
    height: 140px;
}

.remoteAccessToggleGroup > label {
    display: flex;
    justify-content: space-between;
    width: 145px;
    cursor: pointer;
}

.remoteAccessDescription {
    width: 160px;
    margin-top: 11px;
}

.remoteAccessInfo {
    margin-top: 18px;
}


/* Responsiveness on shorter screens */
@media (max-height: 810px) {
    .appNavigation {
        gap: 4px;
    }
    
    .userNavigation {
        gap: 4px;
    }
}

@media (max-height: 705px) {
    .sidebarLogo {
        margin-top: -30px;
    }
    
    .tenantPicker {
        margin-top: 15px;
    }

    .appNavigation {
        margin-top: 10px;
    }
}