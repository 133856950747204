.tableButtons {
    display: flex;
    gap: 16px;
    justify-content: end;
    margin-bottom: 21px;
}

.tableButtons.absolute {
    position: absolute;
    right: 0;
    top: -61px;
}

.tablePagination {
    margin-top: 29px;
}