/* CSS Module Imports */
@value vtpTextPlaceholderInput from "../base/form/vtpTextInput.module.css";
@value vtpTextInputError from "../base/form/vtpTextInput.module.css";

.organisationInfoCard {
  width: 913px;
}

.organisationInfoHeader {
  display: inline-flex;
  width: 100%;
  padding-top: 24px;
  padding-left: 32px;
  justify-content: space-between;
}

.organisationInfoContextMenu {
  margin-top: -13px;
  margin-right: 23px;
}

.companyDomainsRow {
  display: inline-flex;
  width: 100%;
  justify-content: space-between;
  padding: 20px 32px 32px;
  align-items: center;
}

.buttonsRow {
  width: 100%;
  display: inline-flex;
  justify-content: end;
  padding: 0 32px 24px;
  gap: 16px;
}

.organisationInfoDomainList {
  margin-bottom: 0;
  display: flex;
  min-height: 33px;
}

.organisationInfoDomainPill {
  display: flex;
  align-items: center;

  padding: 5px 10px 5px 10px;
  border-radius: 20px;
  margin-left: 10px;

  background-color: #0c615a;
  color: #ffffff;
  font-family: "Mulish";
  font-style: normal;
  font-size: 16px;
  height: 33px;
  text-align: center;
}

.organisationInfoDomainPill.editable {
  border: 2px solid #0c615a;
  background-color: transparent;
  color: #0c615a;
}

.organisationInfoDomainPillAddButton {
  margin-left: 5px;
  cursor: pointer;
}

.organisationInfoDomainPillAddButton:after {
  display: inline-block;
  content: "+";
}

.organisationInfoDomainPillCloseButton {
  margin-left: 5px;
  cursor: pointer;
}

.organisationInfoDomainPillCloseButton:after {
  display: inline-block;
  content: "\00d7";
  color: #ffffff;
}

/* === Overriding VTPTextInput styling === */
.organisationInfoTextInput {
  height: 33px;
  width: 100px;
}

.organisationInfoTextInput:focus-within {
  background-color: unset;
}

.organisationInfoTextInput:hover {
  background-color: unset;
}

.organisationInfoTextInput input {
  color: #0c615a;
  font-size: 16px;
  text-overflow: ellipsis;
}

.organisationInfoTextInput .vtpTextPlaceholderInput {
  font-size: 16px;
}

.organisationInfoTextInput .vtpTextInputPlaceholderX:read-only {
  color: #0c615a;
}

.organisationInfoTextInput .vtpTextInputError {
  font-size: 14px;
  top: -25px;
}

.licenseDuration {
  display: flex;
  flex-direction: column;
  gap: 10px;
  padding-left: 30px;
  padding-bottom: 20px;
}
