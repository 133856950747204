.guidedFlowContainer {
  max-width: 871px;
  width: 100%;
  font-family: "Mulish";
  font-style: normal;
}

.guidedFlowText {
  max-width: 750px;
  text-align: center;
  margin-top: 16px;
}

.guidedFlowButtons {
  display: flex;
  align-self: end;
  margin-top: 32px;
}

.guidedFlowButtons > button {
  margin: 0 16px;
}
