@value vtpPrimaryColor: #1D2132;
@value vtpSecondaryColor: #808080;
@value vtpContrastPrimary: #FFFFFF;
@value vtpContrastSecondary: #EEEEEE;

@value vtpSemanticLightSuccess: #328536;
@value vtpSemanticLightWarning: #B1A217;
@value vtpSemanticLightError: #853232;
@value vtpSemanticLightInformation: #325885;

@value vtpSemanticDarkSuccess: #4DBC52;
@value vtpSemanticDarkWarning: #CEBF3A;
@value vtpSemanticDarkError: #BC4D4D;
@value vtpSemanticDarkInformation: #4D80BC;

@value vtpKandaBrandingPrimary: #0C615A;
@value vtpKandaBrandingAccent: #71E77D;
@value vtpKandaBrandingSecondary: #BCD4CC;
@value vtpKandaBrandingPanelContrast: #E3EBE9;

@value vtpKandaBrandingNeutralsOnLightDeepGrey: #414141;
@value vtpKandaBrandingNeutralsOnLightNondescriptGrey: #808080;

@value vtpKandaBrandingschemeNeutralsOnPrimaryDarkNaturalWhite: #FFFFFF;
@value vtpKandaBrandingSchemeNeutralsOnPrimaryDarkBrightGrey: #EEEEEE;

@value vtpDarkModePrimary: #71E77D;
@value vtpDarkModePrimaryPanel: #414141;

@value vtpBodyStyleText: #565655;

.primaryColor {
    color: vtpPrimaryColor;
}

.secondaryColor {
    color: vtpSecondaryColor;
}

.contrastPrimaryColor {
    color: vtpContrastPrimary;
}

.contrastSecondaryColor {
    color: vtpContrastSecondary;
}

.semanticLightSuccessColor { 
    color: vtpSemanticLightSuccess;
}

.semanticLightWarningColor {
    color: vtpSemanticLightWarning;
}

.semanticLightErrorColor {
    color: vtpSemanticLightError;
}

.semanticLightInformationColor {
    color: vtpSemanticLightInformation;
}

.semanticDarkSuccessColor {
    color: vtpSemanticDarkSuccess;
}

.semanticDarkWarningColor {
    color: vtpSemanticDarkWarning;
}

.semanticDarkErrorColor {
    color: vtpSemanticDarkError;
}

.semanticDarkInformationColor {
    color: vtpSemanticDarkInformation;
}

.kandaBrandingPrimaryColor { 
    color: vtpKandaBrandingPrimary;
}

.kandaBrandingPrimaryGradient {
    background: linear-gradient(90deg, #0C615A 0%, #04524B 100%);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    background-clip: text;
    text-fill-color: transparent;
}

.kandaBrandingAccentColor { 
    color: vtpKandaBrandingAccent;
}

.kandaBrandingSecondaryColor { 
    color: vtpKandaBrandingSecondary
}

.kandaBrandingPanelContrastColor { 
    color: vtpKandaBrandingPanelContrast
}

.kandaBrandingNeutralsOnLightDeepGrey { 
    color: vtpKandaBrandingNeutralsOnLightDeepGrey
}

.kandaBrandingNeutralsOnLightNondescriptGrey {
    color: vtpKandaBrandingNeutralsOnLightNondescriptGrey;
}

.kandaBrandingschemeNeutralsOnPrimaryDarkNaturalWhite { 
    color: vtpKandaBrandingschemeNeutralsOnPrimaryDarkNaturalWhite;
}

.kandaBrandingSchemeNeutralsOnPrimaryDarkBrightGrey { 
    color: vtpKandaBrandingSchemeNeutralsOnPrimaryDarkBrightGrey;
}

.darkModePrimary {
    color: vtpDarkModePrimary;
}

.darkModePrimaryPanel { 
    color: vtpDarkModePrimaryPanel;
}

.bodyStyleText { 
    color: vtpBodyStyleText;
}