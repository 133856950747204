.sortingLabel {
    display: flex;
    align-items: center;
    gap: 1px;
    cursor: pointer;
}

.sortingLabel div {
    line-height: 11px;
}

.sortingLabel svg {
    margin-bottom: 1px;
    min-width: 18px;
}

.sortingLabel path {
    fill: #414141;
}

.sortingLabel.asc,
.sortingLabel.desc{
    color: #0C615A;
}

.sortingLabel.asc path,
.sortingLabel.desc path{
    fill: #0C615A;
}

.sortingLabel.asc path:nth-child(1) {
    transform: translateY(4px);
}

.sortingLabel.asc path:nth-child(2) {
    fill: transparent;
}

.sortingLabel.desc path:nth-child(1) {
    fill: transparent;
}

.sortingLabel.desc path:nth-child(2) {
    transform: translateY(-4px);
}