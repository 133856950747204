/*=== Dropdown Container ===*/
.vtpDropdownContainer {
  border-radius: 14px;
  background-color: #ffffff;
  cursor: pointer;
  box-shadow: 0 0 0 2px #0c615a, 0 4px 4px rgba(0, 0, 0, 0.1);
}

.vtpDropdownContainer > * {
  color: #808080;
}

.vtpDropdownContainer:hover,
.vtpDropdownContainer.open
{
  background-color: #bcd4cc;
  outline-color: transparent;
  box-shadow: none;
  position: relative;
}

.vtpDropdownContainer:hover .vtpDropdownLabel {
  color: #0c615a;
}

/* === Closed dropdown === */
.vtpDropdownHeader {
  padding: 0 20px;
  display: flex;
  justify-content: space-between;
  align-items: center;

  height: 50px;
}

.vtpDropdownLabel {
  color: #808080;
}

.vtpDropdownIcon,
.vtpDropdownOptionsIcon {
  margin-left: 15px;
}

/* === Opened dropdown === */
.vtpDropdownOptionsMain {
  display: flex;
  flex-direction: column;
  background-color: #d0e2e2;
  position: absolute;
  top: 16px;
  
  outline: 0 solid #d0e2e2;
  min-width: inherit;
  min-height: inherit;
  width: inherit;
  border-radius: inherit;
  padding-bottom: 8px;
}

.vtpDropdownOptionsLabel {
  color: #0c615a;
}

.vtpDropdownOptionsIcon {
  transform: rotate(180deg);
}

/* === Option element styling === */
.vtpDropdownOptionDetail {
  display: flex;
  justify-content: space-between;
  padding-left: 20px;
  padding-right: 17px;
  align-items: center;

  width: 100%;
  min-height: 53px;
  cursor: pointer;
  color: #414141;
}

.vtpDropdownOptionDetail:hover {
  background-color: #BCD4CC;
}

.vtpDropdownOptionLabel {
  text-align: left;
}

.vtpDropdownOptionIcon {
  /*height: 35px;*/
  width: 35px;
  /*padding-top: 12px;*/
  margin-left: 10px;
}

.vtpDropdownOption:hover {
  background-color: #bcd5cc;
}


/* Small version */
.vtpDropdownContainerSmall {
  width: 126px;
  height: 35px;
  border-radius: 8px;
  outline: transparent;
  box-shadow: none;
}

.vtpDropdownContainerSmall .vtpDropdownOptionIcon {
  width: 20px;
}

.vtpDropdownContainerSmall .vtpDropdownOptionsIcon,
.vtpDropdownContainerSmall .vtpDropdownIcon{
  min-height: 18px;
  min-width: 18px;
  max-height: 18px;
  max-width: 18px;
}

.vtpDropdownContainerSmall .vtpDropdownHeader {
  height: 35px;
}

.vtpDropdownContainerSmall .vtpDropdownOptionsMain {
  top: 0;
}

.vtpDropdownContainerSmall .vtpDropdownOptionDetail {
  min-height: 35px;
}
