.container {
    display: flex;
    flex-direction: column;
    gap: 16px;
}

.courseCard {
    display: flex;  
    height: 82px;
    justify-content: end;
    align-items: center;
    gap: 54px;
    padding: 0 40px 0 32px;
}

.courseName {
    margin-right: auto;
    display: flex;
    flex-direction: column;
    gap: 7px;
    color: #414141;
}

.statusLabel {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 30px;
    padding: 0 20px;
    border-radius: 30px;
}

.activeLabel{
    background-color: #E1F9E8;
    color: #5AAA5F;
}

.expiredLabel {
    background-color: #E8E8E8;
    color: #808080;
}

.underLine {
    border-bottom: 1px solid #BCD4CC;
    width: 50px;
}

.activeUnderline {
    border-color: #0C615A;
}

.noCourses {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.kandaLogo {
    height: 325px;
    margin-top: 100px;
    margin-bottom: -40px;
}

.kandaLogo {
    opacity: 0.1;
}

.kandaLogo path {
    fill: #1d2132 !important;
}